import React from "react"
import styled from "styled-components"
import { FormattedMessage } from "react-intl"

import Layout from "src/layout"
import { SIZES } from "src/layout/constants"

const Center = styled.section`
  margin: 20vh ${SIZES.margin}px;
  text-align: center;
`

const NotFoundPage: GatsbyPage = () => (
  <Layout title="404">
    <Center>
      <h1>404</h1>
      <FormattedMessage id="menu.notfound" tagName="h2" />
    </Center>
  </Layout>
)

export default NotFoundPage
